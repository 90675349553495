.item-option-selected1 {
    height: 110px;
    width: 110px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #c1c1c1;
    border-radius: 5px;
    color: #717171;
}

.dn {
    display: none;
}

.radioBtn1:checked + .item-option-selected1 {
    border: 2px solid #57a7ff;
}