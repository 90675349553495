@charset "UTF-8";
/* CSS Document */

/*------------------fonts*/
@font-face {
   font-family:"Petco Circular CAP TT";
  src: url("./fonts/TrueTypeTTF/Fonts/PetcoCircularTT-Black.ttf");
	font-weight: 900;}

@font-face {
   font-family:"Petco Circular CAP TT";
  src: url("./fonts/TrueTypeTTF/Fonts/PetcoCircularTT-BlackItalic.ttf");}

@font-face {
   font-family:"Petco Circular CAP TT";
  src: url("./fonts/TrueTypeTTF/Fonts/PetcoCircularTT-Bold.ttf");
font-weight: 700;}

@font-face {
   font-family:"Petco Circular CAP TT";
  src: url("./fonts/TrueTypeTTF/Fonts/PetcoCircularTT-BoldItalic.ttf");}

@font-face {
    font-family:"Petco Circular CAP TT";
  src: url("./fonts/TrueTypeTTF/Fonts/PetcoCircularTT-Book.ttf");
font-weight: 300;}

@font-face {
    font-family:"Petco Circular CAP TT";
  src: url("./fonts/TrueTypeTTF/Fonts/PetcoCircularTT-BookItalic.ttf");}

@font-face {
    font-family:"Petco Circular CAP TT";
  src: url("./fonts/TrueTypeTTF/Fonts/PetcoCircularTT-Italic.ttf");}

@font-face {
    font-family:"Petco Circular CAP TT";
  src: url("./fonts/TrueTypeTTF/Fonts/PetcoCircularTT-Light.ttf");
	font-weight: 200;}

@font-face {
   font-family:"Petco Circular CAP TT";
  src: url("./fonts/TrueTypeTTF/Fonts/PetcoCircularTT-LightItalic.ttf");}

@font-face {
    font-family:"Petco Circular CAP TT";
  src: url("./fonts/TrueTypeTTF/Fonts/PetcoCircularTT-Medium.ttf");
font-weight: 600;}

@font-face {
    font-family:"Petco Circular CAP TT";
  src: url("./fonts/TrueTypeTTF/Fonts/PetcoCircularTT-Regular.ttf");
font-weight: 400;}

@font-face {
  font-family:"Petco Circular CAP TT";
  src: url("./fonts/TrueTypeTTF/Fonts/PetcoCircularTT-Thin.ttf");
	font-weight:100;}

@font-face {
    font-family:"Petco Circular CAP TT";
  src: url("./fonts/TrueTypeTTF/Fonts/PetcoCircularTT-ThinItalic.ttf");}

@font-face {
  font-family:"Petco Circular CAP TT";
  src: url("./fonts/TrueTypeTTF/Fonts/PetcoCircularTT-Ultra.ttf");
 font-weight:bolder;}

@font-face {
  font-family:"Petco Circular CAP TT";
  src: url("./fonts/TrueTypeTTF/Fonts/PetcoCircularTT-UltraItalic.ttf");}

/*---------------------fonts*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
font-family:"Petco Circular CAP TT";
font-weight: 400;
  font-style: normal;}



.contenido-completo-instinct-petco{
width: 100%;
margin: 0 auto;}

/*------------------------------header section*/

.seccion-header-instinct-petco{
width: 100%;
margin: 0;}

.img-banner-desk-header-instinct{
width: 100%;
margin: 0 auto;
display: block;}

.img-banner-mobile-header-instinct{
display: none;}

/*------------------------------end header section*/

/*------------------------------que es instinct section*/

.seccion-instinct-petco{
width: 100%;
margin: 4% 0 0;}

.caja-porque-instinct {
width: 1600px;
max-width: 90%;
margin: 0 auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;}

.titulo-instinct{
width: 100%;
color: #252525;
font-size: 30px;
font-weight: bold;}

.caja-cualidades-instinct{
width: 100%;
margin: 30px auto 0;
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
align-items: center;}

.columna-cualidades-instinct{
width: 32%;
margin: 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;}

.caja-img-cualidades-instinct {
width: 125px;
max-width: 100%;
height: 126px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;}

.img-cualidades-instinct{
width: 100%;
margin: 0;}

.caja-titulo-cualidades-instinct {
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
text-align: center;}

.titulo2-instinct{
color: #252525;
font-size: 22px;
font-weight: bold;}

.caja-texto-cualidades-instinct {
width: 347px;
max-width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
text-align: center;}

.caja2-texto-cualidades-instinct{
width: 347px;
max-width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
text-align: center;}

.texto-instinct{
color: #252525;
font-size: 18px;
font-weight: normal;
margin-top: 10px;}

/*------------------------------end que es instinct  section*/

/*------------------------------categorias instinct section*/
.seccion-categorias-instinct-petco{
width: 100%;
margin: 4% auto 0;}

.caja-categorias-instinct{
width: 1600px;
max-width: 90%;
margin: 0 auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;}

.caja-btn-categorias-instinct{
width: 100%;
margin: 0 auto;
display: flex;
flex-direction: row;
justify-content: center;
cursor: pointer;
align-items: center;}

.btn-categoria-instinct{
width: 50%;
height: 50px;
background-color: transparent;
border: none;
border-bottom: 3px solid #D9D9D9; 
font-family: "Petco Circular CAP TT";
font-size: 30px;
color: #D9D9D9;
cursor: pointer;
font-weight: bold;}

.btn-categoria-instinct.active{
border: none;
border-bottom: 3px solid #F27121; 
color: #F27121;}

.caja-bullets-categorias-instinct{
display: none;}

.caja-subcategorias-instinct{
width: 100%;
margin: 4% auto 0;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
text-align: center;}

.titulo3-instinct{
width: 100%;
color: #252525;
font-size: 28px;
font-weight: bold;}

.caja-btn-subcategorias-instinct{
width: 100%;
margin: 4% 0 0;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;}

.btn-subcategoria-instinct{
width: 32%;
background-color: transparent;
border: none;
border-radius: 6px;
padding: 20px;}

.btn-subcategoria-instinct.active{
background-color: #EA8100;}

.img-bsubcategoria-instinct{
width: 100%;
margin: 0;
border-radius: 6px;}

.img-bsubcategoria2-instinct{
width: 100%;
margin: 0;
border-radius: 6px;
border: 1px solid #C4C4C4;}

.subtitulo-subcategoria-instinct{
font-family: "Petco Circular CAP TT";
width: 100%;
margin-top: 20px;
color: #626262;
font-size: 24px;
font-weight: 500;}

.btn-subcategoria-instinct.active .subtitulo-subcategoria-instinct{
color: #FFF;
font-weight: 900;}

.caja-productos-subcategoria-instinct{
width: 100%;
margin: 4% auto 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;}

.contenedor-info-subcategoria-instinct {
width: 100%;
margin: 0 auto;
background-color: #F8F8F8;
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
padding: 40px 0;
overflow: hidden;}

.contenedor-texto-subcategorias-instinct{
width: 1600px;
max-width: 70%;
margin: 0;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
position: relative;
left: 3%;}

.caja-texto-subcategorias-instinct{
width: 60%;
margin: 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: left;}

.titulo-subcategoria-instinct {
width: 100%;
margin: 0 0 15px;
color: #252525;
font-size: 30px;
font-weight: bold;}

.caja-list-subcategorias-instinct {
width: 100%;
margin: 0;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;}

.punto-lista-subcategoria-instinct {
width: 100%;
margin: 40px auto 0;
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: flex-start;
text-align: left;}

.icon-list-subcategorias-instinct {
width: 22px;
margin: 4px 15px 0 0;}

.texto-list-subcategoria-instinct {
width: 620px;
max-width: 100%;
color: #252525;
font-size: 22px;
font-weight: normal;}

.texto2-list-subcategoria-instinct {
width: 580px;
max-width: 100%;
color: #252525;
font-size: 22px;
font-weight: normal;}

.texto-list-subcategoria-lid-instinct {
width: 620px;
max-width: 100%;
color: #252525;
font-size: 22px;
font-weight: normal;}

.texto2-list-subcategoria-lid-instinct {
width: 580px;
max-width: 100%;
color: #252525;
font-size: 22px;
font-weight: normal;}

.contenedor-img-subcategorias-instinct{
width: 40%;
margin: 0;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;}

.img-original-instinct{
width: 100%;
margin: 0;
position: relative;
right: 20%;}

.img-lid-instinct{
width: 100%;
margin: 0;
position: relative;
right: 8%;}

.contenedor-productos-subcategorias-instinct{
width: 1600px;
max-width: 90%;
margin: 4% auto 0;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;}

.contenedor-productos-instinct {
width: 100%;
margin: 4% auto 0;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;}

.contenedor-productos-lid-instinct {
width: 100%;
margin: 4% auto 0;
display: flex;
flex-direction: row;
justify-content: space-evenly;
align-items: center;}

.caja-producto-instinct{
width: 230px;
max-width: 20%;
margin: 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;}

.caja-producto-lid-instinct{
width: 230px;
max-width: 20%;
margin: 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;}

.img-producto-instinct{
width: 100%;
margin: 0;}

.subtitulo-producto-instinct{
width: 205px;
max-width: 100%;
color: #252525;
font-size: 22px;
font-weight: 500;
margin-top: 15px;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;}

.btn-producto-instinct{
width: 100%;
background-color: transparent;
border: none;
margin-top: 5px;
font-family: "Petco Circular CAP TT";
color: #EA8100;
font-size: 22px;
font-weight: bold;
text-decoration: underline;}

/*------------------------------end categorias instinct section*/

/*------------------------------caja banner final section*/
.seccion-banner-comprar-instinct-petco{
width: 100%;
margin: 5% 0;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
background-color: #EA8100;}

.columna1-banner-comprar-instinct{
width: 50%;
margin: 0;
display: flex;
flex-direction: column;}

.img-banner-comprar-instinct{
width: 100%;
margin: 0;
display: block;}

.img-banner-mobile-comprar-instinct{
display: none;}

.columna2-banner-comprar-instinct{
width: 50%;
margin: 0;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
padding: 30px;}

.titulo4-instinct{
width: 100%;
color: #252525;
font-size: 30px;
font-weight: bold;}

.texto2-instinct {
width: 650px;
max-width: 100%;
color: #252525;
font-size: 22px;
font-weight: normal;
margin: 30px 0;}

.btn-comprar-instinct{
width: 350px;
max-width: 60%;
height: 65px;
display: flex;
justify-content: center;
align-items: center;
padding: 20px;
border: none;
border-radius: 8px;
background: #252525;
font-family: "Petco Circular CAP TT";
color: #FFF;
font-size: 22px;
font-weight: bold;
text-transform: uppercase;}

/*------------------------------end caja banner final section*/

/*------------------------------estilos cajas productos*/

.contenedor-info-subcategoria2-instinct {
width: 100%;
margin: 0 auto;
background-color: #F8F8F8;
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
padding: 40px 0;
overflow: hidden;}

.contenedor-texto-subcategorias2-instinct {
width: 1600px;
max-width: 70%;
margin: 0;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
position: relative;
left: 3.2%;}

.img-lid-instinct{
width: 90%;
margin: 0;
position: relative;
right: 10%;}

.contenedor-info-subcategoria3-instinct {
width: 100%;
margin: 0 auto;
background-color: #F8F8F8;
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
padding: 40px 0;
overflow: hidden;}

.contenedor-texto-subcategorias3-instinct {
width: 1600px;
max-width: 70%;
margin: 0;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
position: relative;
left: 3.2%;}

.img-benatural-instinct{
width: 85%;
margin: 0;
position: relative;
right: 10%;}

.caja-producto3-instinct{
width: 230px;
max-width: 15%;
margin: 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;}

.contenedor-info-subcategoria4-instinct {
width: 100%;
margin: 0 auto;
background-color: #F8F8F8;
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
padding: 40px 0;
overflow: hidden;}

.contenedor-texto-subcategorias4-instinct {
width: 1600px;
max-width: 70%;
margin: 0;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
position: relative;
left: 4.2%;}

.img-original-cat-instinct{
width: 90%;
margin: 0;
position: relative;
right: 25%;}

.contenedor-info-subcategoria5-instinct {
width: 100%;
margin: 0 auto;
background-color: #F8F8F8;
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
padding: 40px 0;
overflow: hidden;}

.contenedor-texto-subcategorias5-instinct {
width: 1600px;
max-width: 70%;
margin: 0;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
position: relative;
left: 1.5%;}

.img-lid-cat-instinct{
width: 90%;
margin: 0;
position: relative;
right: 5%;}

.contenedor-productos5-instinct{
width: 100%;
margin: 4% auto 0;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;}

.contenedor-info-subcategoria6-instinct {
width: 100%;
margin: 0 auto;
background-color: #F8F8F8;
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
padding: 40px 0;
overflow: hidden;}

.contenedor-texto-subcategorias6-instinct {
width: 1600px;
max-width: 70%;
margin: 0;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
position: relative;
left: 1.5%;}

.img-benatural-cat-instinct{
width: 90%;
margin: 0;
position: relative;
right: 10%;}
/*------------------------------end estilos cajas productos*/





@media screen and (max-width:1600px) {	
.titulo-instinct {
font-size: 27px;}
	
.caja-img-cualidades-instinct {
width: 120px;
max-width: 100%;
height: 120px;}
	
.titulo2-instinct {
font-size: 20px;}
	
.caja-texto-cualidades-instinct {
width: 308px;}
	
.caja2-texto-cualidades-instinct {
width: 308px;}
	
.texto-instinct {
font-size: 16px;}
	
.btn-categoria-instinct{
font-size: 27px;}
	
.titulo3-instinct {
font-size: 25px;}	
	
.btn-subcategoria-instinct {
padding: 18px;}
	
.subtitulo-subcategoria-instinct {
font-size: 22px;
margin-top: 18px;}
	
.subtitulo-producto-instinct {
width: 175px;
font-size: 19px;}
	
.btn-producto-instinct {
font-size: 19px;}
	
.contenedor-texto-subcategorias-instinct {
width: 82%;
max-width: 82%;
left: 0.2%;}
	
.titulo-subcategoria-instinct {
font-size: 27px;}
	
.icon-list-subcategorias-instinct {
width: 20px;}
	
.texto-list-subcategoria-instinct {
font-size: 20px;}
	
.texto2-list-subcategoria-instinct {
width: 525px;
font-size: 20px;}
	
.texto-list-subcategoria-lid-instinct {
font-size: 20px;}
	
.texto2-list-subcategoria-lid-instinct {
width: 525px;
font-size: 20px;}
	
.img-original-instinct {
right: 0%;}
	
.titulo4-instinct {
font-size: 27px;}
	
.texto2-instinct {
width: 600px;
max-width: 100%;
font-size: 19px;}
	
.btn-comprar-instinct {
height: 60px;
font-size: 19px;}
	
.contenedor-texto-subcategorias2-instinct {
width: 85%;
max-width: 85%;
left: 4%;}

.contenedor-texto-subcategorias3-instinct {
width: 86%;
max-width: 86%;
left: 4%;}
	
.contenedor-texto-subcategorias4-instinct {
width: 86%;
max-width: 86%;
left: 5.2%;}
	
.img-original-instinct {
right: 35%;}
	
.contenedor-texto-subcategorias5-instinct {
width: 86%;
max-width: 86%;
left: 2%;}
	
.img-benatural-cat-instinct {
right: 25%;}
	
.contenedor-texto-subcategorias6-instinct {
width: 86%;
max-width: 86%;
left: 5.2%;}
}	


@media screen and (max-width:1200px) {
.titulo-instinct {
font-size: 22px;}
	
.caja-img-cualidades-instinct {
width: 100px;
max-width: 100%;
height: 100px;}
	
.titulo2-instinct {
font-size: 16px;}
	
.caja-texto-cualidades-instinct {
width: 251px;}

.caja2-texto-cualidades-instinct {
width: 250px;}	
	
.texto-instinct {
font-size: 13px;}
	
.btn-categoria-instinct{
font-size: 22px;}
	
.titulo3-instinct {
font-size: 20px;}
	
.btn-subcategoria-instinct {
padding: 15px;}
	
.subtitulo-subcategoria-instinct {
font-size: 17px;
margin-top: 15px;}
	
.subtitulo-producto-instinct {
width: 140px;
font-size: 15px;
margin-top: 12px;}
	
.btn-producto-instinct {
font-size: 15px;}
	
.contenedor-texto-subcategorias-instinct {
width: 100%;
max-width: 85%;
left: 5%;}

.titulo-subcategoria-instinct {
font-size: 22px;
margin: 0 0 5px;}
	
.punto-lista-subcategoria-instinct {
margin: 35px auto 0;}
	
.icon-list-subcategorias-instinct {
width: 16px;
margin: 3px 10px 0 0;}
	
.texto-list-subcategoria-instinct {
width: 500px;
font-size: 16px;}
	
.texto2-list-subcategoria-instinct {
width: 420px;
font-size: 16px;}
	
.texto-list-subcategoria-lid-instinct {
width: 500px;
font-size: 16px;}
	
.texto2-list-subcategoria-lid-instinct {
width: 420px;
font-size: 16px;}
	
.img-original-instinct {
right: 28%;}

.titulo4-instinct {
font-size: 22px;}
	
.texto2-instinct {
width: 460px;
max-width: 100%;
font-size: 16px;}
	
.btn-comprar-instinct {
height: 55px;
font-size: 16px;}	
	
.contenedor-texto-subcategorias2-instinct {
width: 100%;
max-width: 85%;
left: 5.5%;}

.img-lid-instinct {
right: 20%;}
	
.contenedor-texto-subcategorias3-instinct {
width: 100%;
max-width: 85%;
left: 5.5%;}

.img-benatural-instinct {
right: 10%;}
	
.contenedor-texto-subcategorias4-instinct {
width: 100%;
max-width: 85%;
left: 5.2%;}
	
.img-lid-cat-instinct {
right: 10%;}
	
.contenedor-texto-subcategorias5-instinct {
width: 100%;
max-width: 85%;
left: 2.2%;}
	
.img-benatural-cat-instinct {
right: 5%;}
	
.contenedor-texto-subcategorias6-instinct {
width: 100%;
max-width: 85%;
left: 1.8%;}
}


@media screen and (max-width:1024px) {
.caja-porque-instinct {
width: 95%;
max-width: 95%;}
	
.caja-categorias-instinct {
width: 95%;
max-width: 95%;}
	
.contenedor-productos-subcategorias-instinct {
width: 95%;
max-width: 95%;}
	
.contenedor-texto-subcategorias-instinct {
width: 90%;
max-width: 90%;
left: 3%;}
	
.img-original-instinct {
right: 18%;}

.columna2-banner-comprar-instinct {
padding: 20px;}
	
.texto2-instinct {
width: 345px;
max-width: 100%;}
	
.contenedor-texto-subcategorias2-instinct {
width: 90%;
max-width: 90%;
left: 4.5%;}
	
.img-lid-instinct {
right: 12%;}
	
.img-benatural-instinct {
right: 5%;}
	
.img-original-cat-instinct {
right: 25%;}
	
.img-lid-cat-instinct {
right: 5%;}
	
.img-benatural-cat-instinct {
right: 0;}
}


@media screen and (max-width:900px) {
.titulo-instinct {
font-size: 20px;}
	
.caja-img-cualidades-instinct {
width: 80px;
max-width: 100%;
height: 80px;}
	
.titulo2-instinct {
font-size: 14px;}
	
.caja-texto-cualidades-instinct {
width: 212px;}
	
.caja2-texto-cualidades-instinct {
width: 212px;}
	
.texto-instinct {
font-size: 11px;}	
	
.btn-categoria-instinct{
font-size: 20px;}
	
.titulo3-instinct {
font-size: 18px;}
	
.caja-subcategorias-instinct {
margin: 30px auto 0;}
	
.caja-btn-subcategorias-instinct {
margin: 30px 0 0;}
	
.btn-subcategoria-instinct {
padding: 10px;}
	
.subtitulo-subcategoria-instinct {
font-size: 15px;
margin-top: 10px;}
	
.subtitulo-producto-instinct {
width: 120px;
font-size: 13px;
margin-top: 10px;}
	
.btn-producto-instinct {
font-size: 13px;}
	
.titulo-subcategoria-instinct {
font-size: 20px;}
	
.contenedor-texto-subcategorias-instinct {
width: 95%;
max-width: 95%;
left: 3%;}
	
.icon-list-subcategorias-instinct {
width: 14px;
margin: 3px 10px 0 0;}
	
.texto-list-subcategoria-instinct {
width: 430px;
font-size: 14px;}
	
.texto2-list-subcategoria-instinct {
width: 360px;
font-size: 14px;}
	
.texto-list-subcategoria-lid-instinct {
width: 430px;
font-size: 14px;}
	
.texto2-list-subcategoria-lid-instinct {
width: 360px;
font-size: 14px;}
	
.titulo4-instinct {
font-size: 20px;}
	
.texto2-instinct {
width: 335px;
max-width: 100%;
font-size: 14px;}
	
.btn-comprar-instinct {
height: 50px;
font-size: 14px;}
	
.contenedor-texto-subcategorias2-instinct {
width: 95%;
max-width: 95%;
left: 4.8%;}
	
.contenedor-texto-subcategorias3-instinct {
width: 95%;
max-width: 95%;
left: 3.2%;}
	
.contenedor-texto-subcategorias4-instinct {
width: 95%;
max-width: 95%;
left: 3.2%;}
	
.contenedor-texto-subcategorias5-instinct {
width: 95%;
max-width: 95%;
left: 2%;}
	
.contenedor-texto-subcategorias6-instinct {
width: 95%;
max-width: 95%;
left: 1%;}
}	


@media screen and (max-width:768px) {
.img-banner-desk-header-instinct{
display: none;}

.img-banner-mobile-header-instinct{
display: block;
width: 100%;
margin: 0 auto;}
	
.seccion-instinct-petco {
margin: 6% 0 0;}
	
.caja-porque-instinct {
width: 90%;
max-width: 90%;}
	
.caja-cualidades-instinct{
flex-direction: column;
margin: 30px 0 0;}
	
.columna-cualidades-instinct {
width: 100%;
margin: 30px 0 0;}
	
.columna-cualidades-instinct:first-child {
margin: 0;}
	
.columna-cualidades-instinct:last-child {
margin: 40px 0 0;}
	
.titulo-instinct {
font-size: 25px;}
	
.caja-img-cualidades-instinct {
width: 100px;
max-width: 100%;
height: 100px;}
	
.titulo2-instinct {
font-size: 20px;}
	
.caja-texto-cualidades-instinct {
width: 315px;}	
	
.caja2-texto-cualidades-instinct {
width: 310px;}

.texto-instinct {
font-size: 16px;}
	
.seccion-categorias-instinct-petco {
margin: 10% auto 0;}
	
.caja-categorias-instinct {
width: 90%;
max-width: 90%;}
	
.btn-categoria-instinct{
font-size: 25px;}
	
.caja-bullets-categorias-instinct{
display: flex;
justify-content: center;
width: 100%;
margin: 3.5% auto 0;}
	
.bullets-categorias-instinct{
width: 20px;
height: 20px;
background-color: #D9D9D9;
border-radius: 50%;
margin: 0 6px;}
	
.bullets-categorias-instinct.active{
width: 20px;
height: 20px;
background-color: #626262;
border-radius: 50%;
margin: 0 6px;}
	
.caja-subcategorias-instinct {
margin: 40px auto 0;}
	
.caja-btn-subcategorias-instinct {
justify-content: flex-start;
overflow-x: auto;
scroll-behavior: smooth;
gap: 20px;
margin: 40px 0 0;}

.caja-btn-subcategorias-instinct::-webkit-scrollbar {
display: none;}
	
.btn-subcategoria-instinct {
min-width: 300px;}
	
.titulo3-instinct {
width: 360px;
max-width: 100%;
font-size: 21px;}
	
.btn-subcategoria-instinct {
padding: 15px;}
	
.subtitulo-subcategoria-instinct {
font-size: 19px;
margin-top: 15px;}

.contenedor-productos-instinct {
justify-content: flex-start;
overflow-x: auto;
scroll-behavior: smooth;
gap: 30px;}
	
.contenedor-productos-instinct::-webkit-scrollbar {
display: none;}
	
.contenedor-productos-lid-instinct {
justify-content: flex-start;
overflow-x: auto;
scroll-behavior: smooth;
gap: 30px;}
	
.contenedor-productos-lid-instinct::-webkit-scrollbar {
display: none;}
	
.caja-productos-subcategoria-instinct {
margin: 10% auto 0;}
	
.caja-producto-instinct {
min-width: 185px;}
	
.caja-producto-lid-instinct {
min-width: 212px;}
	
.subtitulo-producto-instinct {
width: 140px;
font-size: 15px;
margin-top: 10px;}
	
.btn-producto-instinct {
font-size: 15px;
margin-top: 6px;}
	
.contenedor-productos-subcategorias-instinct {
width: 90%;
max-width: 90%;
margin:  7% auto 0;}

.contenedor-texto-subcategorias-instinct {
width: 90%;
max-width: 90%;
flex-direction: column;
left: 0;}
	
.caja-texto-subcategorias-instinct {
width: 100%;}
	
.caja-texto-subcategorias2-instinct{
width: 100%;}
	
.titulo-subcategoria-instinct {
font-size: 25px;
margin: 0 0 15px;
text-align: center;}
	
.icon-list-subcategorias-instinct {
width: 20px;
margin: 3px 10px 0 0;}
	
.texto-list-subcategoria-instinct {
width: 600px;
font-size: 20px;}
	
.texto2-list-subcategoria-instinct {
width: 525px;
font-size: 20px;}
	
.texto-list-subcategoria-lid-instinct {
width: 600px;
font-size: 20px;}
	
.texto2-list-subcategoria-lid-instinct {
width: 525px;
font-size: 20px;}
	
.punto-lista-subcategoria-instinct {
margin: 40px auto 0;}
	
.contenedor-img-subcategorias-instinct {
margin: 40px auto 0;}
	
.contenedor-img-subcategorias2-instinct {
margin: 40px auto 0;}
	
.img-original-instinct {
width: 100%;
min-width: 450px;
right: 0;}
	
.img-lid-instinct {
width: 100%;
min-width: 450px;
right: 0;}
	
.contenedor-info-subcategoria-instinct {
padding: 40px 0 50px;}
	
.seccion-banner-comprar-instinct-petco {
margin: 10% 0 7%;
flex-direction: column;}
	
.columna1-banner-comprar-instinct {
width: 100%;}
	
.img-banner-comprar-instinct{
display: none;}
	
.img-banner-mobile-comprar-instinct{
display: block;
width: 100%;
height: 350px;
margin: 0;
object-fit: cover;}
	
.columna2-banner-comprar-instinct {
width: 100%;
padding: 35px;}
	
.titulo4-instinct {
font-size: 25px;}
	
.texto2-instinct {
width: 500px;
max-width: 100%;
font-size: 20px;
margin: 30px 0;}
	
.btn-comprar-instinct {
height: 55px;
font-size: 18px;}
	
.contenedor-texto-subcategorias2-instinct {
width: 90%;
max-width: 90%;
flex-direction: column;
left: 0;}
	
.img-lid-instinct {
width: 100%;
min-width: 450px;
right: -4.6%;}
	
.contenedor-info-subcategoria2-instinct {
padding: 40px 0 25px;}
	
.contenedor-texto-subcategorias3-instinct {
width: 90%;
max-width: 90%;
flex-direction: column;
left: 0;}
	
.img-benatural-instinct {
width: 100%;
min-width: 450px;
right: 0;}
	
.contenedor-info-subcategoria3-instinct {
padding: 40px 0 40px;}
	
.caja-producto3-instinct {
min-width: 185px;}
	
.contenedor-texto-subcategorias4-instinct {
width: 90%;
max-width: 90%;
flex-direction: column;
left: 0;}
	
.img-original-cat-instinct {
width: 100%;
min-width: 450px;
right: 0;}
	
.contenedor-info-subcategoria4-instinct {
padding: 40px 0 50px;}
	
.contenedor-texto-subcategorias5-instinct {
width: 90%;
max-width: 90%;
flex-direction: column;
left: 0;}
	
.img-lid-cat-instinct {
width: 100%;
min-width: 450px;
right: 0;}
	
.contenedor-info-subcategoria5-instinct {
padding: 40px 0 50px;}
	
.contenedor-texto-subcategorias6-instinct {
width: 90%;
max-width: 90%;
flex-direction: column;
left: 0;}
	
.img-benatural-cat-instinct {
width: 100%;
min-width: 450px;
right: 2%;}
	
.contenedor-info-subcategoria6-instinct {
padding: 40px 0 50px;}
}

@media screen and (max-width:500px) {
.seccion-instinct-petco {
margin: 7% 0 0;}
	
.titulo-instinct {
font-size: 20px;}
	
.caja-cualidades-instinct {
margin: 20px 0 0;}
	
.columna-cualidades-instinct {
margin: 20px 0 0;}
	
.columna-cualidades-instinct:last-child {
margin: 35px 0 0;}
	
.caja-img-cualidades-instinct {
width: 90px;
height: 90px;}
	
.titulo2-instinct {
font-size: 18px;}

.caja-texto-cualidades-instinct {
width: 300px;}

.caja2-texto-cualidades-instinct {
width: 290px;}
	
.texto-instinct {
font-size: 14px;}
	
.btn-categoria-instinct{
font-size: 20px;}
	
.caja-subcategorias-instinct {
margin: 30px 0 0;}
	
.titulo3-instinct {
width: 310px;
max-width: 100%;
font-size: 18px;}
	
.caja-btn-subcategorias-instinct {
margin: 30px 0 0;}
	
.btn-subcategoria-instinct {
min-width: 250px;
padding: 12px;}

.subtitulo-subcategoria-instinct {
font-size: 17px;
margin-top: 12px;}
	
.caja-producto-instinct {
min-width: 180px;}
	
.caja-producto-lid-instinct {
min-width: 180px;}
	
.subtitulo-producto-instinct {
width: 130px;
font-size: 14px;
margin-top: 10px;}
	
.btn-producto-instinct {
font-size: 14px;
margin-top: 5px;}
	
.bullets-categorias-instinct {
width: 16px;
height: 16px;}
	
.bullets-categorias-instinct.active {
width: 16px;
height: 16px;}
	
.contenedor-info-subcategoria-instinct {
padding: 30px 0 40px;}
	
.titulo-subcategoria-instinct {
font-size: 20px;
margin: 0 0 10px;}
	
.punto-lista-subcategoria-instinct {
margin: 35px auto 0;}
	
.icon-list-subcategorias-instinct {
width: 18px;}
	
.texto-list-subcategoria-instinct {
width: 360px;
font-size: 18px;}
	
.texto2-list-subcategoria-instinct {
width: 300px;
font-size: 18px;}
	
.texto-list-subcategoria-lid-instinct {
width: 360px;
font-size: 18px;}
	
.texto2-list-subcategoria-lid-instinct {
width: 300px;
font-size: 18px;}
	
.contenedor-img-subcategorias-instinct {
margin: 35px auto 0;}
	
.img-original-instinct {
width: 100%;
min-width: 330px;
right: 0;}
	
.img-banner-mobile-comprar-instinct {
height: 280px;}
	
.columna2-banner-comprar-instinct {
width: 100%;
padding: 25px;}
	
.titulo4-instinct {
font-size: 20px;}
	
.texto2-instinct {
width: 500px;
max-width: 100%;
font-size: 18px;
margin: 20px 0;}
	
.btn-comprar-instinct {
height: 50px;
font-size: 14px;
padding: 10px;
border-radius: 6px;}	
	
.img-lid-instinct {
width: 100%;
min-width: 330px;}
	
.contenedor-info-subcategoria2-instinct {
padding: 30px 0 20px;}
	
.img-benatural-instinct {
width: 100%;
min-width: 330px;}
	
.contenedor-info-subcategoria3-instinct {
padding: 30px 0 30px;}
	
.caja-producto3-instinct {
min-width: 180px;}
	
.img-original-cat-instinct {
width: 100%;
min-width: 330px;}
	
.contenedor-info-subcategoria4-instinct {
padding: 30px 0 40px;}
	
.img-lid-cat-instinct {
width: 100%;
min-width: 330px;}
	
.contenedor-info-subcategoria5-instinct {
padding: 30px 0 40px;}
	
.img-benatural-cat-instinct {
width: 100%;
min-width: 330px;}
	
.contenedor-info-subcategoria6-instinct {
padding: 30px 0 40px;}
}

@media screen and (max-width:380px) {
.seccion-instinct-petco {
margin: 8% 0 0;}
	
.titulo-instinct {
font-size: 18px;}
	
.caja-cualidades-instinct {
margin: 15px 0 0;}
	
.columna-cualidades-instinct {
margin: 15px 0 0;}
	
.columna-cualidades-instinct:last-child {
margin: 30px 0 0;}
	
.caja-img-cualidades-instinct {
width: 80px;
height: 80px;}
	
.titulo2-instinct {
font-size: 15px;}

.caja-texto-cualidades-instinct {
width: 250px;}

.caja2-texto-cualidades-instinct {
width: 250px;}
	
.texto-instinct {
font-size: 12px;}
	
.btn-categoria-instinct{
font-size: 18px;
height: 45px;}
	
.bullets-categorias-instinct {
width: 14px;
height: 14px;}
	
.bullets-categorias-instinct.active {
width: 14px;
height: 14px;}
	
.caja-subcategorias-instinct {
margin: 20px 0 0;}
	
.titulo3-instinct {
width: 275px;
max-width: 100%;
font-size: 16px;}
	
.caja-btn-subcategorias-instinct {
margin: 20px 0 0;}
	
.btn-subcategoria-instinct {
min-width: 200px;
padding: 10px;}
	
.subtitulo-subcategoria-instinct {
font-size: 16px;
margin-top: 10px;}
	
.caja-producto-instinct {
min-width: 170px;}
	
.caja-producto-lid-instinct {
min-width: 170px;}
	
.subtitulo-producto-instinct {
width: 120px;
font-size: 13px;
margin-top: 8px;}
	
.btn-producto-instinct {
font-size: 13px;
margin-top: 3px;}
	
.punto-lista-subcategoria-instinct {
margin: 30px auto 0;}
	
.icon-list-subcategorias-instinct {
width: 15px;}
	
.titulo-subcategoria-instinct {
font-size: 18px;
margin: 0 0 5px;}
	
.texto-list-subcategoria-instinct {
width: 280px;
font-size: 15px;}
	
.texto2-list-subcategoria-instinct {
width: 280px;
font-size: 15px;}
	
.texto-list-subcategoria-lid-instinct {
width: 280px;
font-size: 15px;}
	
.texto2-list-subcategoria-lid-instinct {
width: 280px;
font-size: 15px;}
	
.contenedor-img-subcategorias-instinct {
margin: 30px auto 0;}
	
.img-original-instinct {
width: 100%;
min-width: 260px;}
	
.contenedor-info-subcategoria-instinct {
padding: 25px 0 35px;}
	
.img-banner-mobile-comprar-instinct {
height: 230px;}
	
.columna2-banner-comprar-instinct {
width: 100%;
padding: 20px;}
	
.titulo4-instinct {
font-size: 18px;}
	
.texto2-instinct {
width: 500px;
max-width: 100%;
font-size: 15px;
margin: 15px 0;}
	
.btn-comprar-instinct {
height: 45px;
font-size: 12px;}
	
.img-lid-instinct {
width: 100%;
min-width: 260px;}
	
.contenedor-info-subcategoria2-instinct {
padding: 25px 0 20px;}
	
.img-benatural-instinct {
width: 100%;
min-width: 260px;}
	
.contenedor-info-subcategoria3-instinct {
padding: 25px 0 25px;}
	
.caja-producto3-instinct {
min-width: 170px;}
	
.img-original-cat-instinct {
width: 100%;
min-width: 260px;}
	
.contenedor-info-subcategoria4-instinct {
padding: 25px 0 35px;}
	
.img-lid-cat-instinct {
width: 100%;
min-width: 260px;}
	
.contenedor-info-subcategoria5-instinct {
padding: 25px 0 35px;}

.img-benatural-cat-instinct {
width: 100%;
min-width: 260px;}
	
.contenedor-info-subcategoria6-instinct {
padding: 25px 0 35px;}
}

.contenedor-productos-instinct::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.caja-producto3-instinct::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.contenedor-productos5-instinct::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.h-scroll {
  overflow-x: scroll;
}

.mw-230 {
  min-width: 230px;
}

	












